const externalArticles = [
  {
    title: "Perché abbiamo bisogno di raccontare le storie? L’esempio di Game of Thrones",
    imageName: "art-lasovsky.jpg",
    link: "https://psiche.santagostino.it/2019/06/19/storie-game-of-thrones/",
  },
  {
    title: "Il teatro della mente: attaccamento e copioni mentali · Cultura Emotiva",
    imageName: "teatro-della-mente.jpg",
    link: "https://culturaemotiva.it/2018/attaccamento-e-copioni-mentali/",
  },
  {
    title: "La memoria. Oltre a quello che vi insegnano a Psicologia Generale · Cultura Emotiva",
    imageName: "la-memoria.jpg",
    link: "https://culturaemotiva.it/2018/la-memoria-oltre-quello-vi-insegnano-psicologia-generale/",
  },
  {
    title: "Una storia di supervisione. Ovvero, il dio caduto · Cultura Emotiva",
    imageName: "storia-supervisione.jpg",
    link: "https://culturaemotiva.it/2017/storia-supervisione-ovvero-dio-caduto/",
  },
  {
    title: "Questione di etichetta · Cultura Emotiva",
    imageName: "labeling-cognitive-distortion-face.jpg",
    link: "https://culturaemotiva.it/2017/835/",
  },
  {
    title: "Che cos'è l'AUTISMO? - Un viaggio tra creature magiche e specchi rotti · Cultura Emotiva",
    imageName: "autismo.jpg",
    link: "https://culturaemotiva.it/2017/cose-lautismo-un-viaggio-creature-magiche-specchi-rotti/",
  },
  {
    title: "CUORI INFRANTI ED OSSA SPEZZATE: Dolore fisico e dolore sociale · Cultura Emotiva",
    imageName: "cuori-infranti.jpg",
    link: "https://culturaemotiva.it/2017/cuori-infranti-ed-ossa-spezzate-dolore-fisico-dolore-sociale/",
  },
  {
    title: "UNIVERSI ARTIFICIALI E MOAI: GLI EFFETTI DEL SOVRAFFOLLAMENTO SU UOMINI E TOPI · Cultura Emotiva",
    imageName: "universi-artificiali.jpg",
    link: "https://culturaemotiva.it/2017/universi-artificiali-moai-gli-effetti-del-sovraffollamento-uomini-topi/",
  },
  {
    title: "Freud ascoltava musica indie · Cultura Emotiva",
    imageName: "freud.jpg",
    link: "https://culturaemotiva.it/2017/freud-ascoltava-musica-indie/",
  },
  {
    title: "Maus, l'Olocausto e la trasmissione transgenerazionale del trauma · Cultura Emotiva",
    imageName: "maus.jpg",
    link: "https://culturaemotiva.it/2017/maus-lolocausto-la-trasmissione-transgenerazionale-del-trauma/",
  },
  {
    title: "Stralci di appunti sulla malinconia. Ovvero, l’utilità della malinconia · Cultura Emotiva",
    imageName: "stralci.jpg",
    link: "https://culturaemotiva.it/2017/stralci-appunti-sulla-malinconia-ovvero-lutilita-della-malinconia/",
  },
  {
    title: "Prime riflessioni sul carcere · Cultura Emotiva",
    imageName: "prime-riflessioni.jpg",
    link: "https://culturaemotiva.it/2017/prime-riflessioni-sul-carcere-parte/",
  },
  {
    title: "Lei (Her): Una Recensione Psicologica · Psicologo 4U",
    imageName: "her.jpg",
    link: "https://www.psicologo4u.com/lei-her-una-recensione-psicologica/",
  },
  {
    title: "C’mon C’mon: la storia psicologica di Johnny · Psicologo 4U",
    imageName: "cmon-cmon.webp",
    link: "https://www.psicologo4u.com/cmon-cmon-la-storia-psicologica-di-johnny/",
  },
];

export default externalArticles;
