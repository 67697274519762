import React from "react"
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

import Button from "./Button"

import { container, imageContainer, textContainer } from "./externalArticleCard.module.css"

const query = graphql`
query AllExternalArticleCoverImages {
  allFile(
    filter: {
      sourceInstanceName: {eq: "assets"}
      relativeDirectory: {eq: "external-articles"}
    }
  ) {
    nodes {
      fileName: base
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH,
        )
      }
    }
  }    
}
`;

const ExternalArticleCard = ({ title, imageName, link }) => {
  const { allFile: { nodes: coverImages } } = useStaticQuery(query);

  // !HACK: as we need to show a specific image for each external article and as Gatsby doesn't allow to have a GraphQL
  // !----- query with parameters here: we need to query all images of external articles and then filter the desired one
  const coverImage = coverImages.find(({ fileName }) => fileName === imageName);

  if (coverImage === undefined) {
    throw new Error(`Cannot render ExternalArticleCard for image ${imageName}, image not found!`);
  }

  return <div className={`${container} flex flex-col pb-4 rounded-lg border-yellow border-t-8`}>
    <GatsbyImage
      image={coverImage.childImageSharp.gatsbyImageData}
      alt={imageName}
      className={imageContainer}
    />

    <div className="flex-grow px-2">
      <div className={textContainer}>
        <h3>{title}</h3>
      </div>
    </div>

    <div className="text-right pt-2 pr-2">
      <Button to={link} text="Leggi" external />
    </div>
  </div>;
};

export default ExternalArticleCard;
